import moment from 'moment';

export default {

   	num_word: (value, words) => {
      value = Math.abs(value) % 100; 
  		var num = value % 10;
  		if(value > 10 && value < 20) return words[2]; 
  		if(num > 1 && num < 5) return words[1];
  		if(num == 1) return words[0]; 
  		return words[2];
    },

    badgeDateParam: (date, ttl) => {

      const isDeadlineWeekend = [6,0].includes(moment(date).day()) ? true : false;

      // Сценарии для "Сегодня"
      if (moment().format('YYYY-MM-DD') == moment(date).format('YYYY-MM-DD')) {
        if(ttl == 1) {
          // На выполнение задачи всего 1 день и дэдлайн сегодня
          return { class: 'badge--red', text: moment(date).format('Сегодня HH:mm') };
        } else {
          // Сегодня дэдлайн
          return { class: 'badge--red', text: 'Сегодня'};
        }
      }

      // Сценарии для "Завтра"
      if (moment().add(1,'days').format('YYYY-MM-DD') == moment(date).format('YYYY-MM-DD')) {
        if(ttl == 1) {
          // На выполнение задачи всего 1 день и дэдлайн завтра
          return { class: isDeadlineWeekend ? 'badge--red' : 'badge--blue', text: moment(date).format('Завтра HH:mm') };
        } else {
          // Завтра дэдлайн
          return { class: isDeadlineWeekend ? 'badge--red' : 'badge--blue', text: 'Завтра'};
        }
      }

      // Сценарии для "После завтра"
      if (moment().add(2,'days').format('YYYY-MM-DD') == moment(date).format('YYYY-MM-DD')) {
        // Послезавтра дэдлайн, цвет в зависимости от выходной / рабочий день
        return { class: isDeadlineWeekend ? 'badge--red' : 'badge--gray', text: moment(date).format('DD.MM.YYYY') };
      }

      // Всё что через 3 и более дней
      if (moment().add(2,'days') < moment(date)) {
        return { class: 'badge--gray', text: moment(date).format('DD.MM.YYYY') };
      }

    },

    statusWorkParam: (status) => {
    	switch (status) {
        case 'done':
          return {class: 'point--success', name: 'Выполнено'};
        case 'failed':
          return {class: 'point--error', name: 'Не выполнено'};
        case 'issue':
          return {class: 'point--warning', name: 'Есть проблемы'};
        default:
          return '';
      }
    },

    statusParentParam: (status) => {
      switch (status) {
        case 'done':
          return {class: 'point--success', name: 'Выполнено'};
        case 'expired':
          return {class: 'point--error', name: 'Просрочено'};
        case 'failed':
          return {class: 'point--warning', name: 'Не выполнено'};
        default:
          return '';
      }
    },

    toBase64: (file) => {
      return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = reject;
      });
    }, 

    validateFields: (form) => {
      let isError = false;
      // Проверка на обязательное заполнение
      for (var key in form) {
        // Проверка обязательности ввода
        if(form[key].required.status) {
        // Прроверка заполнения
          if(form[key].value === null || form[key].value.length === 0) {
            form[key].error.status = true;
            form[key].error.message = form[key].required.message;
            isError = true;
          }
        }
      }
      if(isError) return false;
      // Проверка регулярками
      for (key in form) {
        // Цикл по регуляркам
        for (var i = 0; i <= form[key].validate.length - 1; i++) {
          // Валидация регуляркой значения
          if(!form[key].validate[i].regexp.test(form[key].value.toString().trim())) {
            form[key].error.status = true;
            form[key].error.message = form[key].validate[i].msg;
            isError = true;
            break;
          }
        }
      }
      if(isError) return false;
      return true;
    },
    
}