<template>
	<div class="input-custom" :class="{'input-custom--binding': modelValue !== null}" @click="openCalendar()">
		<div class="input-custom__label"> {{ label }} </div>
		<div class="input-custom__value" v-if="modelValue !== null"> 
			{{ moment(modelValue).format('DD.MM.YYYY') }} 
		</div>
		<img class="input-custom__ico" src="../../assets/img/calendar.svg">
	</div>
	<CalendarDialogue ref="calendarDialogue" @setDate="setDate" />
</template>

<script setup>
  import CalendarDialogue from '../modals/CalendarDialogue.vue';
</script>

<script>
	export default {
	    name: 'DatePicker',
	    props: [ 'modelValue', 'label' ],
	    methods: {
	    	openCalendar () {
      		this.$refs.calendarDialogue.show({
            label: this.label,
            date: this.modelValue,
          });
	    	},
	    	setDate (date) {
	    		this.$emit('update:modelValue', date);
	    	},
	    },
    }
</script>

<style scoped>
	


</style>