<template>

  <MessageDialogue ref="messageDialogue" />
  <ConfirmDialogue ref="confirmDialogue" />
	<Breadcrumbs :param="breadcrumbs" />

	<div class="load-container" :class="{'load': isLoad}">
		<div v-if="template !== null">

			<div class="info-container">
				<div class="info-container__item info-container__item--big">
					<div class="info-container__label">
						Задание
					</div>	
					<div class="info-container__text" v-html="formatedName(template.name)"></div>
				</div>
				<div class="info-container__item" v-if="template.locTitle !== null">
					<div class="info-container__label">
						Место
					</div>	
					<div class="info-container__text">
						{{ template.locTitle }}
					</div>
				</div>
				<div class="info-container__item" v-if="template.object !== null">
					<div class="info-container__label">
						Объект проверки
					</div>	
					<div class="info-container__text">
						{{ template.object }}
					</div>
				</div>
			</div>

			<div class="form-group">
				<DatePicker label="Дата назначения" v-model="form.date" />
			</div>

			<div class="form-group">
				<RadioPicker 
					label="Квалификация"
					title="Выберите квалификацию"
					placeholder="Поиск квалификации"
					v-model="form.functionId"
					:list="functions"
				/>
			</div>

			<div class="form-group">
				<RadioPicker 
					label="Исполнитель"
					title="Выберите исполнителя"
					placeholder="Поиск исполнителя"
					v-model="form.employeeId"
					:list="filteredEmployees"
				/>
			</div>

			<div class="form-group">
				<div class="form-control">
					<textarea v-model="form.employeeComment" rows="5"></textarea>
					<label> Комментарий </label>
				</div>
			</div>

			<!-- Если подзадача еще не была создана -->
			<div class="page__buttons">
				<a class="btn btn--default btn--lg btn--inline" :class="{'disabled': isDisabledSaveButton}" @click="saveTask($event)"> 
					<img class="btn--ico btn--ico--left" src="@/assets/img/2.svg">
					<div class="btn--text"> Сохранить </div>
					<div class="btn--loader"></div>
				</a>
				<a class="btn btn--red btn--lg btn--inline" v-if="template.id !== null" @click="deleteTask($event)"> 
					<img class="btn--ico btn--ico--left" src="@/assets/img/cross-white.svg">
					<div class="btn--text"> Удалить </div>
					<div class="btn--loader"></div>
				</a>
				<router-link :to="prevLink" class="btn btn--outline btn--lg btn--inline" v-if="template.id == null"> 
					<img class="btn--ico btn--ico--left" src="@/assets/img/cross-blue.svg">
					<div class="btn--text"> Отменить </div>
					<div class="btn--loader"></div>
				</router-link>
			</div>


		</div>
	</div>	
</template>

<script setup>
  import Breadcrumbs from '@/components/Breadcrumbs.vue';
  import MessageDialogue from '@/components/modals/MessageDialogue.vue';
  import ConfirmDialogue from '@/components/modals/ConfirmDialogue.vue';
  import DatePicker from '@/components/ux/DatePicker.vue';
  import RadioPicker from '@/components/ux/RadioPicker.vue';
</script>

<!-- "Исполнителя", "Дней на исполнение" и "Специализацию исполнителя" -->

<script>
	export default {

	    name: 'PageChildTemplate',
	    
	    data: () => ({
	    	isLoad: true,
	     	isError: false,
	     	
	     	breadcrumbs: null, 
		    template: null,

		    form: {
		    	date: null,
		    	functionId: null,
		    	employeeId: null,
		    	employeeComment: null,
		    },

		    employees: [],
		    functions: [],
	    }),

	    created () {
	      Promise.all([this.loadChildTemplate(), this.loadFunctions(), this.loadEmployees()])
	        .then(() => {
	          this.beforeRender();
	        })
	        .catch((e)=> {
	          console.log(e)
	          this.isError = true;
	        })
	        .finally(() => {
	          this.isLoad = false;
	        })
	    },

	    watch: {
	    	// Устанавливаем сотрудника в зависимости от отфильтрованного списка
	    	'form.functionId': function () {
	        const idx = this.filteredEmployees.findIndex( (employee) => { return employee.id == this.form.employeeId });
	        this.form.employeeId = (idx !== -1) ? this.form.employeeId : null;
	    	} 
	    },

	    computed: {
	    	filteredEmployees () {
	    		let list = [];
		        if(this.form.functionId !== null) {
		          	this.employees.forEach((employee) => {
		          		const idx = employee.functions.findIndex((func) => { return func.id === this.form.functionId });
		          		if(idx !== -1) { list.push(employee); }
		          	});
		        } else {
		          list = this.employees;
		        }
		        return list;
	    	},
	    	formatedName () {
	    		return (name) => {
	    			return name.replace(/(?:\r\n|\r|\n)/g, '<br>');
	    		}
	    	},
	    	actionType () {
		        return this.$route.meta.actionType;
		    },
		    house_id () {
		        return this.$route.params.house_id;
		    },
		    section_id () {
		        return this.$route.params.section_id;
		    },
		    task_id () {
		        return this.$route.params.task_id;
		    },
		    user () {
		        return this.$store.state.auth.user;
		    },
		    pageHint () {
		        return this.$route.meta.hint;
		    },
		    pageName () {
		        return this.$route.meta.name;
		    },
		    prevPage () {
        	return 'Page' + this.pageName + 'Task';
      	},
      	prevLink () {
        	return {name: this.prevPage, params: {house_id: this.house_id, section_id: this.section_id, task_id: this.task_id}};
      	},
      	isDisabledSaveButton () {
      		const data = [];
        	for (let key in this.form) {
        		data.push(this.form[key]);
        	}
        	return data.includes(null) ? true : false;
      	},
      	currentLabel () {
      		switch (this.template.actionType) {
      			case 'work':
      				return { name: 'Новая работа' };
      			case 'control':
      				return { name: 'Новый контроль' };
      			case 'review':
      				return { name: 'Новый осмотр' };
      			default:
      				return null;
      		}
      	},

  		},
  		methods: {
  			
  			beforeRender () {
  				this.breadcrumbs = {
      			current: { name: this.currentLabel.name },
          	prev: {name: 'Назад', link: this.prevLink},
	        }

	        if(this.template.dateCreated && this.template.dateCreated !== null) {
	        	this.form.date = this.moment(this.template.dateCreated).format("YYYY-MM-DD");
	        } else {
	        	this.form.date = this.moment().add(1, 'days').format("YYYY-MM-DD");
	        }

	        // Сотрудник
	        this.form.employeeId = this.template.employeeId;
	        // Квалификация - скинет или оставит сотрудника watcher
	        this.form.functionId = this.template.functionId;
	        // Комментарий
	        this.form.employeeComment = this.template.supplierComment;
  			},

  			// Удалить задачу
  			async deleteTask (e) {
  				const btn = e.currentTarget;
  				btn.classList.add('btn--load');
  				const isConfirm = await this.$refs.confirmDialogue.show({
          	message: 'Удалить поставленную задачу?',
          	okButton: 'Удалить',
          	cancelButton: 'Отменить',
        	});
        	if(isConfirm) {
        		try {
	  					const r = await this.axios.delete(this.$store.state.api_endpoint + '/checklist/' + this.actionType + '/' + this.user.id + '/' + this.task_id + '/childTemplate');
	  					if(r.status == 200) {
		          	this.$router.push(this.prevLink);
	  					}
	  				} catch (e) {
	  					this.$refs.messageDialogue.show({ message: e.message, okButton: 'Подтвердить' });
	  				}
        	}
  				btn.classList.remove('btn--load');
  			},

  			// Сохранить задачу
  			async saveTask (e) {
  				const btn = e.currentTarget;
  				btn.classList.add('btn--load');

  				const params = {
  					ttl: this.template.ttl,
  					employeeId: this.form.employeeId,
  					functionId: this.form.functionId,
  					employeeComment: this.form.employeeComment,
  					dateCreated: this.moment(this.form.date).format('YYYY-MM-DDT00:00:00+00:00'),
  				};
  				try {
  					await this.axios.post(this.$store.state.api_endpoint + '/checklist/' + this.actionType + '/' + this.user.id + '/' + this.task_id + '/childTemplate', params);
						await this.$refs.confirmDialogue.show({
            	message: 'Задача поставлена',
            	okButton: 'Продолжить',
            	cancelButton: false,
          	});
          	this.$router.push(this.prevLink);
  				} catch (e) {
  					this.$refs.messageDialogue.show({ message: e.message, okButton: 'Подтвердить' });
  				}
  				btn.classList.remove('btn--load');
  			},

  			// Подгрузить шаблон
  			async loadChildTemplate () {
  				const r = await this.axios.get(this.$store.state.api_endpoint + '/checklist/' + this.actionType + '/' + this.user.id + '/' + this.task_id + '/childTemplate');
	        this.template = r.data;
  			},

  			// Подгрузить все квалификации
  			async loadFunctions () {
  				const r = await this.axios.get(this.$store.state.api_endpoint + '/functions/');
        	const list = r.data.data;
        	const data = [];
        	for (let key in list) {
        		data.push({ id: list[key].id, name: list[key].description })
        	}
        	this.functions = data;
  			},

  			// Подгрузить всех сотрудников
  			async loadEmployees () {
  				const r = await this.axios.get(this.$store.state.api_endpoint + '/employees/?companyId=' + this.user.companyId + '&houseId=' + this.house_id + '&active=true&detailed=true');
	        this.employees = r.data.data;
  			},

  		}
	}
</script>

<style scoped>
	.info-container {
	    margin-bottom: 10px;
	}
	.page__buttons {
		margin-top: 10px;
	}
	.page__buttons .btn:not(:last-child) {
		margin-bottom: 10px;
	}
</style>