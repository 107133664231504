<template>
    <div class="wrap">
        <TopMenu />
        <slot />
    </div>
    <BottomMenu />
</template>

<script setup>
  import TopMenu from '../components/TopMenu.vue'
  import BottomMenu from '../components/BottomMenu.vue'
</script>

<script>
    export default {
        name: "MainLayuot",
        created () {},
    }
</script>