import { createStore } from 'vuex'
import axios from 'axios'

// Create a new store instance.
export default createStore({

  // admin
  // AuFsgbk3crq9

  state: {
    api_endpoint: process.env.VUE_APP_API_ENDPOINT,
    auth: {
      token: localStorage.getItem('token') || '',
      user: JSON.parse(localStorage.getItem('user')) || {},
    },
    roles: null,
    companies: null,
  },

  getters : {
    // bool статус авторизации
    isLoggedIn: state => !!state.auth.token,
    // Текущий пользователь
    authUser: state => state.auth.user,
    // Получить роль по ID
    getRoleById: (state) => (roleId) => {
      if (state.roles == null) { return null; }
      const idx = state.roles.findIndex((role) => { return role.name == roleId });
      if(idx !== -1) {
        return state.roles[idx];
      } else {
        return null;
      }
    },
    // Получить компанию по ID
    getCompanyById: (state) => (companyId) => {
      
      if (state.companies == null) { return null; }

      const idx = state.companies.findIndex((company) => { return company.id == companyId });

      console.log(companyId);
      console.log(state.companies);
      console.log(idx);

      if(idx !== -1) {
        return state.companies[idx];
      }
    },
  },

  mutations: {
    // Устанавливаем авторизацию
    setAuth(state, { user, token }) {
      // Устанавливаем access_token
      state.auth.token = token;
      // Устанавливаем пользователя
      state.auth.user = user;
    },
    logout(state) {
      // Сбрасываем access_token
      state.auth.token = '';
    },
    setRoles(state, { roles }) {
      state.roles = roles;
    },
    setCompanies(state, { companies }) {
      state.companies = companies;
    }
  },

  actions: {

    // Список ролей системы
    async getRoles ({commit, state}) {
      if (state.roles === null) {
        const r = await axios.get(state.api_endpoint + '/roles/');
        const roles = r.data.data;
        commit('setRoles', { roles });
      }
    },

    // Список компаний в системе
    async getCompanies ({commit, state}) {
      if (state.companies === null) {
        const r = await axios.get(state.api_endpoint + '/companies/');
        const companies = r.data.data;
        commit('setCompanies', { companies });
      }
    },

    // Выход из системы
    async logout({commit}) {
      commit('logout');
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      delete axios.defaults.headers.common['Authorization'];
      return true;
    },

    // Авторизация
    async login({commit, state}, param ) {
      try {
        // Запрос авторизации
        const tokenResponse = await axios.post(state.api_endpoint + '/token', param, {headers:{'Content-Type': 'application/x-www-form-urlencoded'}});
        // Всё прошло нормально
        if (tokenResponse.status == 200) {
          // Полученный токен
          const token = tokenResponse.data.access_token;
          // В local storage устанавливаем token
          localStorage.setItem('token', token);
          // Axios по умолчанию будет отправлять авторизацию с запросами
          axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
          // Что за пользователь?
          const meResponse = await axios.get(state.api_endpoint + '/employees/me');
          // Полученный объект пользователя
          const user = meResponse.data;
          // В local storage устанавливаем user
          localStorage.setItem('user', JSON.stringify(user));
          // Авторизация успешна
          commit('setAuth', {user, token});
          return true;
        }
      } catch (e) {e}
      // Авторизация ошибочна
      localStorage.removeItem('token');
      return false;
    },
  },

});