<template>
	<svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
		<rect x="1" y="1" width="14" height="10" rx="2" :stroke="color" stroke-width="2"/>
		<path d="M11 14C9.34315 14 8 12.6569 8 11" :stroke="color" stroke-width="2" stroke-linecap="round"/>
		<circle cx="8" cy="6" r="1" :fill="color"/>
		<circle cx="11" cy="6" r="1" :fill="color"/>
		<circle cx="5" cy="6" r="1" :fill="color"/>
	</svg>
</template>
<script>
  	export default {
    	name: 'CommentSmall',
    	props: ['color'],
   	}
</script>