<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" class="nav__ico">
  <g clip-path="url(#clip0_1_3)">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M10 5C5.79288 5 3.14029 8.17466 2.09196 9.7501C1.97251 9.92961 1.97166 10.1289 2.07654 10.2999C3.02578 11.8468 5.51512 15 10 15C14.4849 15 16.9742 11.8468 17.9235 10.2999C18.0283 10.1289 18.0275 9.92961 17.908 9.7501C16.8597 8.17466 14.2071 5 10 5ZM0.426901 8.64213C1.55498 6.94684 4.72939 3 10 3C15.2706 3 18.445 6.94684 19.5731 8.64213C20.1162 9.45839 20.1483 10.4982 19.6281 11.3459C18.56 13.0865 15.5462 17 10 17C4.45376 17 1.44002 13.0865 0.371897 11.3459C-0.148278 10.4982 -0.116249 9.45839 0.426901 8.64213Z" fill="#AAB4B9"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M10 8C8.89543 8 8 8.89543 8 10C8 11.1046 8.89543 12 10 12C11.1046 12 12 11.1046 12 10C12 9.44772 12.4477 9 13 9C13.5523 9 14 9.44772 14 10C14 12.2091 12.2091 14 10 14C7.79086 14 6 12.2091 6 10C6 7.79086 7.79086 6 10 6C10.5523 6 11 6.44772 11 7C11 7.55228 10.5523 8 10 8Z" fill="#AAB4B9"/>
  </g>
  <defs>
  <clipPath id="clip0_1_3">
  <rect width="20" height="20" fill="white"/>
  </clipPath>
  </defs>
  </svg>
</template>
