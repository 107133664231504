<template>
  <div class="page">
    <div class="empty-msg"> Данные отсутствуют </div>
  </div>
</template>

<script>
  export default {
    name: 'PageSignals',
    data: () => ({
      isLoad: true,
    }),
    mounted () {},
    methods: {}
  }
</script>

<style scoped>
  @media (max-width: 767px) {}
</style>