<template>
	
  <MessageDialogue ref="messageDialogue" />
  <ConfirmDialogue ref="confirmDialogue" />

	<Breadcrumbs :param="breadcrumbs" />

	<div class="load-container" :class="{'load': isLoad}">
		<div v-if="task !== null">

			<!-- Общий блок метаинформации задачи -->
			<div class="info-container">
				<div class="info-container__item info-container__item--big">
					<div class="info-container__label">
						Задание
					</div>	
					<div class="info-container__text" v-html="formatedName(task.name)"></div>
				</div>
				<div class="info-container__item">
					<div class="info-container__label">
						Крайний срок
					</div>	
					<div class="info-container__text">
						{{ helpers.badgeDateParam(task.dateExpire, task.ttl).text }}
					</div>
				</div>
				<div class="info-container__item" v-if="task.statusLocal !== null">
					<div class="info-container__label">
						Статус
					</div>	
					<div class="info-container__text">
						<span> {{ helpers.statusWorkParam(task.statusLocal).name }} </span>
						<div class="point" :class="helpers.statusWorkParam(task.statusLocal).class"></div>
					</div>
				</div>   
				<div class="info-container__item" v-if="task.locTitle">
					<div class="info-container__label">
						Место
					</div>	
					<div class="info-container__text">
						{{ task.locTitle }}
					</div>
				</div>
				<div class="info-container__item" v-if="task.object">
					<div class="info-container__label">
						Объект задания
					</div>	
					<div class="info-container__text">
						{{ task.object }}
					</div>
				</div>
				<!-- Для контроля в отдельном блоке -->
 				<template v-if="task.actionType != 'control'">
					<div class="info-container__item" v-if="task.supplierComment !== null && task.supplierComment !== ''">
						<div class="info-container__label">
							Комментарий постановщика
						</div>	
						<div class="info-container__text">
							{{ task.supplierComment }}
						</div>
					</div>
				</template>
			</div>

			<!-- Для контроля -->
 			<template v-if="task.actionType == 'control'">

				<div class="info-container info-container--label">
					<div class="info-container__badge">
						Результат работы
					</div>
					<div class="info-container__item">
						<div class="info-container__label">
							Статус работы
						</div>	
						<div class="info-container__text">
							<span> {{ helpers.statusParentParam(task.parentStatus).name }} </span>
							<div class="point" :class="helpers.statusParentParam(task.parentStatus).class"></div>
						</div>
					</div>
					<div class="info-container__item" v-if="task.supplierComment !== null && task.supplierComment !== ''">
						<div class="info-container__label">
							Комментарий исполнителя
						</div>	
						<div class="info-container__text">
							{{ task.supplierComment }}
						</div>
					</div>
					<div class="info-container__item">
						<GalleryPicker v-model="task.parentImgLinks" :uniq="task.id + 'galleryParent'" :viewMode="true" :label="form.images.label"/>
					</div>
				</div>

				<div class="info-container info-container--label">
					<div class="info-container__badge">
						Результат контроля
					</div>
					<div class="info-container__item">
						<div class="form-control">
							<textarea v-model="form.comment.value" rows="5"></textarea>
							<label> Комментарий контролёра </label>
						</div>
					</div>
					<div class="info-container__item">
						<GalleryPicker v-model="form.images.value" :uniq="task.num" :viewMode="false" :label="form.images.label"/>
					</div>
				</div>

			</template>

			<!-- Для всего остального -->
 			<template v-if="task.actionType !== 'control'">
				<div class="form-group" :class="{ 'error': form.comment.error.status }">
					<div class="form-error"> {{ form.comment.error.message }} </div>
					<div class="form-control">
						<textarea v-model="form.comment.value" rows="5"></textarea>
						<label> {{ form.comment.label }} </label>
					</div>
				</div>
				<div class="form-group">
					<GalleryPicker v-model="form.images.value" :uniq="task.id + 'galleryCurrent'" :viewMode="false" :label="form.images.label"/>
				</div>
 			</template>

 			<!-- Дочерняя задача -->
			<transition name="slide-fade">
				<div class="info-container info-container--child" v-if="task.child !== null">
					<div class="info-container__hint">
						{{ currentLabel.name }}
					</div>
					<div class="info-container__content">
						<div class="info-container__item">
							<div class="info-container__label">
								Дата назначения
							</div>	
							<div class="info-container__text">
								{{ moment(task.child.dateCreated).format('DD MMMM YYYY') }}
							</div>
						</div>
						<div class="info-container__item" v-if="task.child.employee">
							<div class="info-container__label">
								Исполнитель
							</div>	
							<div class="info-container__text">
								{{ task.child.employee.name }}
							</div>
						</div>
						<div class="info-container__item" v-if="task.child.supplierComment !== null">
							<div class="info-container__label">
								Комментарий постановщика
							</div>	
							<div class="info-container__text">
								{{ task.child.supplierComment }}
							</div>
						</div>
					</div>
					<div class="btn-group">
						<router-link class="link-uppercase me-3" :to="nextLink"> 
							<img class="btn--ico btn--ico--left" src="@/assets/img/eye-blue.svg">
							<div class="btn--text"> Посмотреть </div>
							<div class="btn--loader"></div>
						</router-link>
						<a class="link-uppercase" @click="deleteChild($event)"> 
							<img class="btn--ico btn--ico--left" src="@/assets/img/cross-blue.svg">
							<div class="btn--text" style="margin-top: 2px;"> Удалить </div> 
							<div class="btn--loader"></div>
						</a>
					</div>
				</div>
			</transition>

			<!-- Элементы управления -->
			<div class="page__buttons">
				<a 
					@click="saveTask($event, 'done')" 
					class="btn btn--default btn--lg btn--inline" 
					:class="{'disabled' : isDisabledCompleteButton}"> 
					<img class="btn--ico btn--ico--left" src="@/assets/img/2.svg">
					<div class="btn--text"> Выполнено </div>
					<div class="btn--loader"></div>
				</a>
				<a 
					@click="saveTask($event, 'issue')" 
					class="btn btn--yellow btn--lg btn--inline" 
					:class="{'disabled' : isDisabledWarningButton}" 
					v-if="task.actionType == 'review' || task.actionType == 'control'"> 
					<img class="btn--ico btn--ico--left" src="@/assets/img/exclamation-white.svg" v-if="task.child == null">
					<div class="btn--text" v-if="task.child == null"> Есть проблемы </div>
					<div class="btn--text" v-if="task.child !== null"> Запустить в работу </div>
					<div class="btn--loader"></div>
				</a>
				<a 
					@click="saveTask($event, 'failed')" 
					class="btn btn--red btn--lg btn--inline" 
					:class="{'disabled' : isDisabledErrorButton}"> 
					<img class="btn--ico btn--ico--left" src="@/assets/img/3.svg">
					<div class="btn--text"> Не выполнено </div>
					<div class="btn--loader"></div>
				</a>
			</div>

		</div>
	</div>
</template>

<script setup>
  import Breadcrumbs from '@/components/Breadcrumbs.vue';
  import GalleryPicker from '@/components/ux/GalleryPicker.vue';

  import MessageDialogue from '@/components/modals/MessageDialogue.vue';
  import ConfirmDialogue from '@/components/modals/ConfirmDialogue.vue';
</script>

<script>

export default {
    
    name: 'PageTask',
    
    data: () => ({
    	
    	isLoad: true,
      isError: false,

      breadcrumbs: null, 
	   
	    task: null,

	    form: {
	    	comment: {
	    		label: 'Комментарий исполнителя',
	    		value: '',
	    		required: {
	    			status: false,
	    		},
	    		validate: [
	    			{regexp: /.{5,}/, msg: 'Минимум 5 символов'}, 
	    		],
	    		error: {
	    			status: false,
	    			message: null,
	    		}
	    	},
	    	images: {
	    		label: 'Фотографии',
	    		value: [],
	    		required: {
	    			status: false,
	    		},
	    		validate: [],
	    		error: {
	    			status: false,
	    			message: null,
	    		}
	    	},

	    },
    }),

    created () {
      Promise.all([this.loadTask()])
        .then(() => {
          this.beforeRender();
        })
        .catch((e)=> {
          console.log(e)
          this.isError = true;
        })
        .finally(() => {
          this.isLoad = false;
        })
    },

    computed: {
    	formatedName () {
    		return (name) => {
    			return name.replace(/(?:\r\n|\r|\n)/g, '<br>');
    		}
    	},
    	actionType () {
        return this.$route.meta.actionType;
      },
    	house_id () {
        return this.$route.params.house_id;
      },
      section_id () {
        return this.$route.params.section_id;
      },
      task_id () {
        return this.$route.params.task_id;
      },
      user () {
        return this.$store.state.auth.user;
      },
      pageHint () {
        return this.$route.meta.hint;
      },
      pageName () {
        return this.$route.meta.name;
      },
      nextPage () {
        return 'Page' + this.pageName + 'ChildTemplate';
      },
      nextLink () {
        return {name: this.nextPage, params: {house_id: this.house_id, section_id: this.section_id, task_id: this.task_id}};
      },
      prevPage () {
        return 'Page' + this.pageName + 'Section';
      },
      prevLink () {
        return {name: this.prevPage, hash: '#' + this.task_id, params: {house_id: this.house_id, section_id: this.section_id}};
      },
      isDisabledCompleteButton () {
      	return false;
      },
      isDisabledErrorButton () {
      	return false;
      },
      isDisabledWarningButton () {
      	return false;
      },
      currentLabel () {
    		switch (this.task.child.actionType) {
    			case 'work':
    				return { name: 'Новая работа' };
    			case 'control':
    				return { name: 'Новый контроль' };
    			case 'review':
    				return { name: 'Новый осмотр' };
    			default:
    				return null;
    		}
    	},
    },

    watch: {
    	'form.comment.value': function () {
				this.form.comment.error.status = false;
    	},
    },

    methods: {

    	beforeRender () {
    
    		this.breadcrumbs = {
          current: {name: this.pageHint + ' от ' + this.moment(this.task.dateCreated).format('DD.MM.YYYY')},
          prev: {name: this.task.locTitle, link: this.prevLink},
        }

        this.form.comment.value = this.task.employeeComment == null ? '' : this.task.employeeComment;
    		this.form.images.value = this.task.imgLinks;

  		  if(this.task.child !== null) {
    			this.loadEmployee();
    		}

    	},

    	async loadTask () {
				const r = await this.axios.get(this.$store.state.api_endpoint + '/checklist/taskInfo/' + this.user.id + '/' + this.task_id);
        if(r.status == 200) {
          this.task = r.data;

        }
    	},

    	async loadEmployee () {
    		const r = await this.axios.get(this.$store.state.api_endpoint + '/employees/' + this.task.child.employeeId);
        if(r.status == 200) {
          this.task.child.employee = r.data;
        }
    	},

    	// Удалить задачу
			async deleteChild (e) {

				const btn = e.currentTarget;
				btn.classList.add('btn--load');

				const isConfirm = await this.$refs.confirmDialogue.show({
        	message: 'Удалить поставленную задачу?',
        	okButton: 'Удалить',
        	cancelButton: 'Отменить',
      	});

      	if(isConfirm) {
					try {
						await this.deleteChildTask();
					} catch (e) {
						this.$refs.messageDialogue.show({ 
							message: e.message, 
							okButton: 'Подтвердить'
						});
					}
      	}

				btn.classList.remove('btn--load');
			},

			async deleteChildTask () {
				const r = await this.axios.delete(this.$store.state.api_endpoint + '/checklist/' + this.actionType + '/' + this.user.id + '/' + this.task_id + '/childTemplate');
				if(r.status == 200) {
					this.task.child = null;
				}
			},


	    async saveTask(e, status) {

        const btn = e.currentTarget;

        // Обязательно необходимо прикрепить фото
        if(this.task.imgRequired && this.form.images.value.length == 0) {
        	this.$refs.messageDialogue.show({
            message: 'Для продолжения фотофиксация обязательна',
            okButton: 'Подтвердить',
          });
          return;
        }

        // Если проставляется не done обязательно нужен комментарий или фото
        if (status !== 'done' && this.form.comment.value.trim().length == 0 && this.form.images.value.length == 0) {
        	this.$refs.messageDialogue.show({
            message: 'Добавьте комментарий или прикрепите фото',
            okButton: 'Подтвердить',
          });
          return;
        }
        
        // Включаем барашка
        btn.classList.add('btn--load');

        const params = {
      		statusLocal: status,
      		employeeComment: this.form.comment.value,
      		images: this.form.images.value,
        };

        try {
        	// Сохраняем результат задачи
        	const r = await this.axios.patch(this.$store.state.api_endpoint + '/checklist/' + this.actionType + '/' + this.user.id + '/' + this.task_id, params);

        	// Если что-то пошло не так, ничего не делаем
        	if(r.status !== 200) { return; }

					// Проставляем в задачу локальный статус
          this.task.statusLocal = params.statusLocal;

					// Сохраняем осмотр или контроль со статусом проблема 
					if (this.task.statusLocal == 'issue' &&  ['control', 'review'].includes(this.actionType)) {

						try {
							// Делаем запрос на шаблон дочерней задачи
  						await this.axios.get(this.$store.state.api_endpoint + '/checklist/' + this.actionType + '/' + this.user.id + '/' + this.task_id + '/childTemplate');
						} catch (e) {
							// Шаблон фаталит
							{ return; }
						}

						// Уже есть дочерняя задача
						if (this.task.child !== null) {
							// Сразу переходим в список задач
							return this.$router.push(this.prevLink);
						}

						// Назначить работу?
						const isCreateWork = await this.$refs.confirmDialogue.show({
	            message: 'Назначить работу?',
	            okButton: 'Да',
	            cancelButton: 'Нет',
	          });
						// Согласился
						if(isCreateWork) {
							// Создание дочерней задачи
							return this.$router.push(this.nextLink);
						} else {
							// Ждём немного, дальше будет еще один confirm
        			await new Promise(r => setTimeout(r, 400));
						}
					} else {
						// Сохраняем задачу с конечным статусом
	          if(this.task.child !== null) {
	          	// Удаляем дочернюю задачу если есть to do
							await this.deleteChildTask();	          
	          }
					}

					// Вернуться в список?
					const isConfirm = await this.$refs.confirmDialogue.show({
	          message: 'Данные сохранены',
	          okButton: 'Вернуться в список',
	          cancelButton: false,
	        });

					// Согласился
	        if(isConfirm) {
	        	// Возвращаемся к списку задач
						this.$router.push(this.prevLink);
	        }


        } catch (e) {
        	this.$refs.messageDialogue.show({
            message: e.message,
            okButton: 'Подтвердить',
          });
        }

        btn.classList.remove('btn--load');

	    },

    },

  }
</script>

<style scoped>

	.page__buttons {
		margin-top: 10px;
	}

	.page__buttons .btn:not(:last-child) {
		margin-bottom: 10px;
	}

	.info-container__text {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	/*.info-container__text .point {*/
		/*margin-right: 8px;*/
	/*}*/

	.info-container--label {
		border: 1px solid rgba(var(--cozh-blue-code), .35);
		position: relative;
		margin-top: 35px;
	}
	
	.info-container--label textarea {
		padding: 18px 0px 0px 0px;
	}

	.info-container--label .form-control textarea:focus {
	  border-color: transparent;
	}

	.info-container--label .form-control label {
		left: 0px;
		padding-left: 1px;
		padding-top: 0px;
	}

	.info-container--label .gallery {
		padding: 0px;
	}

	.info-container__badge {
		position: absolute;
    top: -26px;
    left: 18px;
    padding: 8px 10px;
    background-color: var(--cozh-blue);
    color: var(--cozh-white);
    border-radius: 8px 8px 0px 0px;
    font-size: 10px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.50px;
    line-height: 1;

	}


  @media (max-width: 767px) {}

</style>