<template>
	<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M13 8C13 10.7614 10.7614 13 8 13M13 8C13 5.23858 10.7614 3 8 3M13 8H15M8 13C5.23858 13 3 10.7614 3 8M8 13V15M3 8C3 5.23858 5.23858 3 8 3M3 8H1M8 3V1" :stroke="color" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
		<circle cx="8" cy="8" r="1" :fill="color"/>
	</svg>
</template>
<script>
  	export default {
    	name: 'TargetSmall',
    	props: ['color'],
   	}
</script>