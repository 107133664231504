import { h } from 'vue'
import { RouterView } from 'vue-router'
import { createWebHistory, createRouter } from 'vue-router';
import store from './store';

import PageLogin from './pages/PageLogin.vue';
import PageNotFound from './pages/PageNotFound.vue';

import PageHouses from "./pages/PageHouses.vue";
import PageHouse from "./pages/PageHouse.vue";
import PageSection from "./pages/PageSection.vue";
import PageTask from "./pages/PageTask.vue";
import PageChildTemplate from "./pages/PageChildTemplate.vue";

import PageSignals from "./pages/PageSignals.vue";
import PageProfile from "./pages/PageProfile.vue";

const routes = [

  // Страница 404
  {
    path: '/:catchAll(.*)',
    component: PageNotFound,
    meta: {
      title: '404',
      layout: 'empty-layout',
      requiresAuth: false,
    }
  },

  // Страница авторизации
  {
    path: '/login',
    component: { render: () => h(RouterView) },
    meta: {
      title: 'Логин',
      layout: 'empty-layout',
      requiresAuth: false,
    },
    children: [
      { 
        path: '',
        name: 'PageLogin',
        component: PageLogin,
      },
    ]
  },


  // Работы
  {
    path: '/',
    component: { render: () => h(RouterView) },
    meta: {
      title: 'Работы',
      hint: 'Работа',
      name: 'Work',
      ico: '2.svg',
      actionType: 'work',
      layout: 'main-layout',
      requiresAuth: true,
    },
    children: [
      { 
        path: '',
        name: 'PageWork',
        component: PageHouses,
      },
      { 
        path: ':house_id',
        name: 'PageWorkHouse',
        component: PageHouse,
      },
      { 
        path: ':house_id/section/:section_id/:pattern',
        name: 'PageWorkSection',
        component: PageSection,
      },
      { 
        path: ':house_id/section/:section_id/:pattern/task/:task_id',
        name: 'PageWorkTask',
        component: PageTask,
      },
    ]
  },

  // Осмотры
  {
    path: '/review',
    component: { render: () => h(RouterView) },
    meta: {
      title: 'Осмотры',
      hint: 'Осмотр',
      name: 'Review',
      actionType: 'review',
      ico: '1.svg',
      layout: 'main-layout',
      requiresAuth: true,
    },
    children: [
      { 
        path: '',
        name: 'PageReview',
        component: PageHouses,
      },
      { 
        path: ':house_id',
        name: 'PageReviewHouse',
        component: PageHouse,
      },
      { 
        path: ':house_id/section/:section_id/:pattern',
        name: 'PageReviewSection',
        component: PageSection,
      },
      { 
        path: ':house_id/section/:section_id/:pattern/task/:task_id',
        name: 'PageReviewTask',
        component: PageTask,
      },
      { 
        path: ':house_id/section/:section_id/:pattern/task/:task_id/child-template',
        name: 'PageReviewChildTemplate',
        component: PageChildTemplate,
      },
    ]
  },




  // Контроль
  {
    path: '/control',
    component: { render: () => h(RouterView) },
    meta: {
      title: 'Контроль',
      hint: 'Контроль',
      name: 'Control',
      ico: '3.svg',
      actionType: 'control',
      layout: 'main-layout',
      requiresAuth: true,
    },
    children: [
      { 
        path: '',
        name: 'PageControl',
        component: PageHouses,
      },
      { 
        path: ':house_id',
        name: 'PageControlHouse',
        component: PageHouse,
      },
      { 
        path: ':house_id/section/:section_id/:pattern',
        name: 'PageControlSection',
        component: PageSection,
      },
      { 
        path: ':house_id/section/:section_id/:pattern/task/:task_id',
        name: 'PageControlTask',
        component: PageTask,
      },
     { 
        path: ':house_id/section/:section_id/:pattern/task/:task_id/child-template',
        name: 'PageControlChildTemplate',
        component: PageChildTemplate,
      },
    ]
  },


  // Сигналы
  {
    path: '/signals',
    component: { render: () => h(RouterView) },
    meta: {
      title: 'Сигналы',
      name: 'Сигналы',
      ico: '4.svg',
      layout: 'main-layout',
      requiresAuth: true,
    },
    children: [
      { 
        path: '',
        name: 'PageSignals',
        component: PageSignals,
      },
    ]
  },

  // Профиль
  {
    path: '/profile',
    component: { render: () => h(RouterView) },
    meta: {
      title: 'Профиль',
      name: 'Профиль',
      ico: '5.svg',
      layout: 'main-layout',
      requiresAuth: true,
    },
    children: [
      { 
        path: '',
        name: 'PageProfile',
        component: PageProfile,
      },
    ]
  },

  
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    return { top: 0 }
  },
});

router.beforeEach((to, from, next) => {
  // Устанавливаем title страницы
  document.title = 'Личный кабинет - ' + to.meta.title;
  // Если страница закрыта авторизацией
  if(to.matched.some(record => record.meta.requiresAuth)) {
    // Убеждаемся что авторизация есть
    if (store.getters.isLoggedIn) {
      // Пропускаем
      next();
      return;
    }
    // Редиректим на авторизацию
    next('/login');
  } else {
    // Страница без авторизации, пропускаем
    next();
  }
});

export default router;