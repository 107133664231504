<template>
	<div class="nav">
		<router-link to="/review" class="nav__item">
			<IconReview />
			<div class="nav__name"> Осмотры </div>
		</router-link>
		<router-link to="/" class="nav__item">
			<IconWork />
			<div class="nav__name"> Работы </div>
		</router-link>
		<router-link to="/control" class="nav__item">
			<IconControl />
			<div class="nav__name"> Контроль </div>
		</router-link>
		<router-link to="/signals" class="nav__item">
			<IconSignals />
			<div class="nav__name"> Сигналы </div>
		</router-link>
		<router-link to="/profile" class="nav__item">
			<IconProfile />
			<div class="nav__name"> Профиль </div>
		</router-link>
	</div>
</template>

<script setup>
  import IconWork from './icons/IconWork.vue';
  import IconReview from './icons/IconReview.vue';
  import IconControl from './icons/IconControl.vue';
  import IconSignals from './icons/IconSignals.vue';
  import IconProfile from './icons/IconProfile.vue';
</script>

<script>
  export default {
    name: 'bottomMenu',
    data: () => ({}),
  }
</script>

<style scoped>
	.nav {
		background-color: var(--cozh-white);
    position: sticky;
    bottom: 0px;
    left: 0px;
    width: 100%;
    height: 77px;
    min-height: 77px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 10px;
    z-index: 9;
    border-top: 1px solid rgba(var(--cozh-light-grey-code), .5);
	}
	.nav__item {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		flex-grow: 1;
    flex-basis: 0;
    padding: 10px 0px;
	}
	.nav__name {
		font-size: 10px;
		font-weight: 600;
		line-height: 1;
		letter-spacing: -0.004em;
		color: var(--cozh-dark-grey);
		transition: all .2s ease-out;
	}
	.nav__item:deep(svg) {
		margin-bottom: 8px;
	}
	.nav__item:deep(svg path) {
		transition: all .2s ease-out;
	}
	.nav__item.router-link-active .nav__name {
		color: var(--cozh-blue);
	}
	.nav__item.router-link-active:deep(svg path) {
		fill: var(--cozh-blue);
	}
  @media (max-width: 320px) {}

</style>