<template>
	<svg width="15" height="8" viewBox="0 0 15 8" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M1.5 5L3.5 7L9.5 1M7.5 7L13.5 1" :stroke="color" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
	</svg>
</template>
<script>
  	export default {
    	name: 'IconDoubleCheck',
    	props: ['color'],
   	}
</script>