<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" class="nav__ico">
  <g clip-path="url(#clip0_1_13)">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2ZM0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10Z" fill="#AAB4B9"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M14.2071 6.79289C14.5976 7.18342 14.5976 7.81658 14.2071 8.20711L9.20711 13.2071C8.81658 13.5976 8.18342 13.5976 7.79289 13.2071L5.79289 11.2071C5.40237 10.8166 5.40237 10.1834 5.79289 9.79289C6.18342 9.40237 6.81658 9.40237 7.20711 9.79289L8.5 11.0858L12.7929 6.79289C13.1834 6.40237 13.8166 6.40237 14.2071 6.79289Z" fill="#AAB4B9"/>
  </g>
  <defs>
  <clipPath id="clip0_1_13">
  <rect width="20" height="20" fill="white"/>
  </clipPath>
  </defs>
  </svg>
</template>
