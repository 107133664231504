<template>
  <popup-modal ref="popup" :size="'xs'" @close="close">
    <div class="popup">
    	<div class="picker">
    		<div class="picker__title">
    			{{ title }}
    		</div>
    		<div class="picker__field">
          <input type="text" v-model="search" :placeholder="placeholder">
          <img src="@/assets/img/search.svg">
        </div>
        <div class="picker__list">
          <div v-if="filteredList.length == 0">
            Совпадений не найдено
          </div>
          <div class="radio" v-for="(item) in filteredList" :key="item.id">
    				<input type="radio" :id="'picker-' + item.id" :value="item.id" v-model="picked" />
    				<label :for="'picker-' + item.id"> 
              <span v-html="backlight(item.name)"></span>
            </label>
    			</div>
    		</div>
    		<div class="picker__bottom">
    			<a @click="confirm($event)" class="btn btn--default btn--sm btn--inline calendar__btn"> 
    				<img class="btn--ico btn--ico--left" src="@/assets/img/2.svg">
    				<div class="btn--text"> Выбрать </div>
    				<div class="btn--loader"></div>
    			</a>
    		</div>
    	</div>
    </div>
  </popup-modal>
</template>

<script>
  import PopupModal from './PopupModal.vue';

  export default {
    
    name: 'ModalRadioPicker',
    
    components: { PopupModal },

    data: () => ({
    	picked: null,
    	search: null,

      label: null,
      title: null,
      placeholder: null,
      selected: null,
      list: null,
    }),

    computed: {
      backlight () {
        return (text) => {
          if(this.search !== null && this.search !== '') {
            const idx = text.toLowerCase().indexOf(this.search.toLowerCase());
            const substring = text.substring(idx, idx + this.search.length);
            return text.replace(substring, '<span class="text-yellow">' + substring + '</span>');
          }
          return text;
        }
      },
      filteredList () {
        let list = [];
        if(this.search !== null && this.search !== '') {
          this.list.forEach((item) => {
            if(item.name.toLowerCase().indexOf(this.search.toLowerCase()) >= 0) {
              list.push(item);
            }
          });
        } else {
          list = this.list;
        }
        return list;
      },
    },

    methods: {
      show(opts = {}) {
        this.label = opts.label;
        this.title = opts.title;
        this.placeholder = opts.placeholder;
        this.selected = opts.selected;
        this.list = opts.list;

        this.$refs.popup.open();

        this.picked = this.selected;
        this.search = null;
      },
      close() {
        this.$refs.popup.close();
      },
      confirm () {
        this.$emit('setValue', this.picked);
        this.close();
      },
    
    },
  };
</script>

<style scoped>

  .popup {
    padding: 0px;
    padding-top: 45px;
  }

	.picker__title {
		font-size: 20px;
    font-weight: 500;
    line-height: 1;
    text-align: left;
    margin-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
	}

  .picker__field {
    border-top: 1px solid var(--cozh-light-grey);
    border-bottom: 1px solid var(--cozh-light-grey);
    position: relative;
  }

	.picker__field input {
		background-color: var(--cozh-white);
    border: none;
    border-radius: 0px;
    color: var(--cozh-black);
    padding: 0px 58px 0px 20px;
    width: 100%;
    font-size: 14px;
    font-weight: 500;
    transition: all .2s ease;
    min-height: 55px;
    position: relative;
	}

	.picker__field input::placeholder {
		font-size: 13px;
		line-height: 1;
		font-weight: 500;
		color: var(--cozh-black);
		opacity: .35;
    transition: all .2s ease-out;
	}

  .picker__field input:focus::placeholder {
    opacity: 0;
    visibility: hidden;
  }

  .picker__field img {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    user-select: none;
    pointer-events: none;
  }


	.picker__list {
    max-height: 300px;
    overflow-y: auto;
    margin-left: 25px;
    margin-top: 20px;
    margin-right: 15px;
    margin-bottom: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
	}


  .picker__list::-webkit-scrollbar {
    width: 4px;
    background: var(--cozh-light-grey);
  }
  .picker__list::-webkit-scrollbar-thumb {
    background: rgba(var(--cozh-blue-code), 1);
    -webkit-border-radius: 1ex;
  }
  .picker__list::-webkit-scrollbar-corner {
    background: var(--cozh-light-grey);
  }

  .picker__list .radio:not(:last-child) {
    margin-bottom: 13px;
  }


	.picker__bottom {
    border-top: 1px solid var(--cozh-light-grey);
		padding: 20px 20px 20px 20px;
	}

  @media (max-width: 767px) {}
</style>