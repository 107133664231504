<template>
	<div class="notfound">
		<div class="notfound__content">
			<h1 class="notfound__title"> 404 </h1>
			<div class="notfound__name">
				Ошибка! 
			</div>
			<div class="notfound__text">
				К сожалению, запрашиваемая Вами страница не найдена...
			</div>
			<router-link :to="{name: 'PageWork'}" class="btn btn--default btn--lg btn--inline notfound__btn" >
				Вернуться на сайт
			</router-link>
		</div>
	</div>
</template>

<script>

  export default {
    name: 'PageNotFound',
    data: () => ({
    }),
    mounted () {
    },
    methods: {
  
    },

  }

</script>

<style scoped>

	.notfound {
		height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
	}

	.notfound__title {
		font-size: 50px;
    line-height: 1;
    margin-bottom: 20px;
    color: var(--cozh-blue);
	}
	.notfound__content {
		padding: 30px;
    border-radius: 8px;
    background-color: var(--cozh-white);
    max-width: calc(100% - 40px);
    width: 540px;
	}
	.notfound__btn {
		margin-top: 20px;
	}
	.notfound__name {
		font-size: 18px;
		font-weight: 600;
	}
	.notfound__text {
		font-size: 16px;
		margin-top: 5px;
	}

</style>