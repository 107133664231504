<template>
	<div class="input-custom" :class="{'input-custom--binding': modelValue !== null}" @click="openModal()">
		<div class="input-custom__label"> {{ label }} </div>
		<div class="input-custom__value" v-if="modelValue !== null"> 
			{{ displayName }}
		</div>
		<img class="input-custom__ico" src="../../assets/img/check-down.svg">
	</div>
	<RadioPickerDialogue ref="radioPickerDialogue" @setValue="setValue" />
</template>

<script setup>
  import RadioPickerDialogue from '../modals/RadioPickerDialogue.vue';
</script>

<script>
	export default {
	    
	    name: 'RadioPicker',
	    
	    props: [ 'modelValue', 'label', 'list', 'title', 'placeholder' ],

	    computed: {
	    	displayName () {
	    		var index = this.list.findIndex((item) => {
			        return item.id == this.modelValue;
			    });
			    return (index !== -1) ? this.list[index].name : null;
	    	},
	    },

	    methods: {
	    	openModal () {
	    		this.$refs.radioPickerDialogue.show({
            label: this.label,
            title: this.title,
            placeholder: this.placeholder,
            selected: this.modelValue,
            list: this.list,
          });
	    	},
	    	setValue (val) {
	    		// Можно Передавать null для очищения
	    		this.$emit('update:modelValue', val);
	    	},
	    },

    }
</script>

<style scoped>
	


</style>