<template>
	<svg width="9" height="8" viewBox="0 0 9 8" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M1.5 7L7.5 1M7.5 7L1.5 1" :stroke="color" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
	</svg>
</template>
<script>
  	export default {
    	name: 'IconCross',
    	props: ['color'],
   	}
</script>