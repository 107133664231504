<template>
  <div class="gallery">
    <div class="gallery__label">
      {{ label }}
    </div>
    <div class="gallery__container" :class="{'empty': modelValue.length == 0}">
      <div class="gallery__item" v-for="(image, index) in modelValue"  :key="'file-' + index">
        <a class="gallery__image" :style="{backgroundImage: 'url(' + image + ')'}" :data-fancybox="'gallery-' + uniq" :data-src="image"></a>
        <a class="gallery__delete" @click="deleteImage(index)" v-if="!viewMode"></a>
      </div>
    </div>
    <div class="gallery__button" v-if="!viewMode">
      <input type="file" :id="uniq" @change="onFileChanged($event)" multiple="true" accept=".png,.gif,.jpeg,.jpg" ref="input">
      <label :for="uniq" class="btn btn--outline btn--md btn--inline">
        <img class="btn--ico btn--ico--left" src="../../assets/img/6.svg">
        <div class="btn--text">Добавить фото</div>
      </label>
    </div>
  </div>
</template>

<script>
	export default {
	    name: 'GalleryPicker',
	    props: ['modelValue', 'uniq', 'label', 'viewMode'],
	    data: () => ({
	    }),
	    methods: {
	    	deleteImage(idx) {
		      let files = this.modelValue;
		      files.splice(idx, 1);
	    		this.$emit('update:modelValue', files);
          this.$refs.input.value = null;
	    	},
	    	async onFileChanged (e) {
	        const input = e.currentTarget;
	        if(input.files.length > 0) {
	        	let files = this.modelValue;
          	Array.from(input.files).forEach(async file => {
          		let base64 = await this.helpers.toBase64(file);
          		files.push(base64);
  				    this.$emit('update:modelValue', files);
          	});
	        }
		    },
	    },
    }
</script>

<style scoped>

.gallery {
  background-color: var(--cozh-white);
  padding: 18px;
  border-radius: 8px;
  border: 1px solid transparent;
  transition: all .2s ease-out;
  position: relative;
}
.gallery__label {
  color: rgba(11, 40, 54, .35);
  font-size: 13px;
  font-weight: 500;
  line-height: 1;
}
.gallery__container {
  border-radius: 8px;
  width: 100%;
  margin-top: 15px;
  padding-bottom: 10px;
  margin-bottom: 5px;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  overflow-x: auto;
}
.gallery__container::-webkit-scrollbar {
  display: none;
}
  
.gallery__container.empty {
  background-color: rgba(233, 241, 244, 0.5);
  height: 90px;
  padding: 0px;
  margin-bottom: 15px;
}
.gallery__container.empty::before {
  content: url('../../assets/img/7.svg');
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 38px;
}
.gallery__item {
  height: 90px;
  width: 130px;
  min-width: 130px;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
}
.gallery__item:not(:last-child){
    margin-right: 10px;
}
.gallery__button {
}
.gallery__button input {
   opacity: 0;
   position: absolute;
   z-index: -1;
}

.gallery__image {
  height: 100%;
  width: 100%;
  background-origin: border-box;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  overflow: hidden;
  display: flex;
  box-sizing: border-box;
  background-color: #d9e4eb;
}






/*
.gallery__image::before {
  content: '';
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background-color: transparent;
  transition: all .2s ease-out;
}
.gellery--delete .gallery__image::before {
  background: linear-gradient(180deg,rgba(11,40,54,0),rgba(11,40,54,.5));
}
.gallery__image::after {
  content: url('../../assets/img/scale.svg');
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  visibility: hidden;
  transition: all .2s ease-out;
}
.gallery__image:hover {
  border-color: rgba(var(--cozh-blue-code), .75);
}
.gallery__image:hover::before {
  background-color: rgba(var(--cozh-blue-code), .75);
}
.gallery__image:hover::after {
  opacity: 1;
  visibility: visible;
}
*/


.gallery__delete {
  position: absolute;
  top: 5px;
  right: 5px;
  height: 24px;
  width: 24px;
  background-color: rgba(var(--cozh-black-code), .15);
  z-index: 1;
  border-radius: 50%;
  backdrop-filter: blur(18px);
}
.gallery__delete::before {
  content:  url('../../assets/img/3.svg');
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}


</style>