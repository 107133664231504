<template>
	<div class="load-container" :class="{'load': isLoad}">
		<div v-if="items.length > 0">
			<router-link 
				:to="nextLink(item.houseId)"
				v-for="(item) in items" 
				:key="item.id" 
				class="card" 
				:style="{backgroundImage: 'url(' + houseImg(item.imgLinks) + ')' }">
				<div class="badge badge--blue"> 
					{{ item.count }} 
					{{ helpers.num_word(item.count, ['работа', 'работы', 'работ']) }} 
				</div>
				<div class="card__name"> {{ item.address }} </div>
			</router-link>
		</div>
		<div class="empty-msg" v-else> Данные отсутствуют </div>
  </div>
</template>

<script>
  export default {
    name: 'PageHouses',

    data: () => ({
      items: [],
      isLoad: true,
      isError: false,
    }),
    
    computed: {
    	actionType () {
        return this.$route.meta.actionType;
      },
      pageName () {
        return this.$route.meta.name;
      },
      nextPage () {
      	return 'Page' + this.pageName + 'House';
      },
      nextLink () {
      	return (house_id) => {
	      	return {name: this.nextPage, params: {house_id: house_id}};
      	}
      },
      houseImg () {
      	return (imgLinks) => {
      		if(!imgLinks) {
      			return '/img/plug.png';
      		} else {
      			return imgLinks.length > 0 ? imgLinks[0] : '/img/plug.png';
      		}
      	}
      },
    },
    created () {
      Promise.all([this.loadItems()])
	      .then(() => {
	        this.beforeRender();
	      })
	      .catch(()=> {
	        this.isError = true;
	      })
	      .finally(() => {
	        this.isLoad = false;
	      })
    },
    methods: {
    	beforeRender () {},
    	async loadItems () {
        const r = await this.axios.get(this.$store.state.api_endpoint + '/checklist/tasks/' + this.$store.state.auth.user.id  + '?actionType=' + this.actionType + '&active=true');
        if(r.status == 200) {
          this.items = r.data.data;
        }
      },
    },
  }

</script>

<style scoped>


	.card {
		height: 210px;
		width: 100%;
		background-color: var(--cozh-white);
		border-radius: 8px;
		background-position: center;
		background-size: cover;
		overflow: hidden;
		display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: flex-end;
    padding: 20px;
    position: relative;
	}

	.card::before {
		content: '';
		position: absolute;
		left: 0px;
		top: 0px;
		height: 100%;
		width: 100%;
    background: linear-gradient(180deg, rgba(11, 40, 54, 0) 0%, rgba(11, 40, 54, 0.25) 100%);
    z-index: 1;
	}

	.card:not(:last-child) {
		margin-bottom: 10px;
	}

	.card__name {
		font-size: 18px;
		font-weight: 600;
		line-height: 1.2;
		height: fit-content;
		color: var(--cozh-white);
    z-index: 2;
	}

	.card .badge {
    z-index: 2;
    position: absolute;
    right: 10px;
    top: 10px;
	}

  @media (max-width: 767px) {}

</style>