<template>
    <slot/>
</template>
<script setup></script>
<script>
    export default {
        name: "EmptyLayuot",
    }
</script>
<style scoped>
</style>