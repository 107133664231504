<template>
	<Breadcrumbs :param="breadcrumbs" />
	<div class="load-container" :class="{'load': isLoad}">
		<div v-if="data !== null">
			<router-link 
				v-for="(item) in data.tasks" 
				:key="item.locId" 
				class="block"
				:to="nextLink(item.locId, item.locationPatternBase64)"
				>
				<div class="badge" :class="item.urgentTask ? 'badge--red' : 'badge--blue'"> 
					{{ item.count }} 
					{{ helpers.num_word(item.count, ['работа', 'работы', 'работ']) }} 
				</div>
				<div class="block__name"> {{ item.locTitle }} </div>
				<div class="block__bottom">
					<div class="block__btn">
						<img src="@/assets/img/1.svg">
					</div>
					<div class="block__text">
						Перейти к работам
					</div>
				</div>
			</router-link>
		</div>
		<div class="empty-msg" v-else> Данные отсутствуют </div>
  </div>

</template>


<script setup>
  import Breadcrumbs from '@/components/Breadcrumbs.vue';
</script>

<script>

  export default {
    
    name: 'PageHouse',

    data: () => ({
      isLoad: true,
      isError: false,

    	data: null,

    	breadcrumbs: null, 
    }),
    
    computed: {
    	actionType () {
        return this.$route.meta.actionType;
      },
      house_id () {
        return this.$route.params.house_id;
      },
      user () {
        return this.$store.state.auth.user;
      },
      pageName () {
        return this.$route.meta.name;
      },
      nextPage () {
      	return 'Page' + this.pageName + 'Section';
      },
      prevPage () {
      	return 'Page' + this.pageName;
      },
      nextLink () {
      	return (section_id, pattern) => {
	      	return {name: this.nextPage, params: {house_id: this.house_id, section_id: section_id, pattern: pattern}};
      	}
      },
      prevLink () {
	      return {name: this.prevPage};
      },
    },

    created () {
      Promise.all([this.loadData()])
	      .then(() => {
	        this.beforeRender();
	      })
	      .catch(()=> {
	        this.isError = true;
	      })
	      .finally(() => {
	        this.isLoad = false;
	      })
    },

    methods: {
    	beforeRender () {
    		this.breadcrumbs = {
	    		current: {name: this.data.address},
	    		prev: {name: 'Дома', link: this.prevLink},
	    	}
    	},
    	async loadData () {
        const r = await this.axios.get(this.$store.state.api_endpoint + '/checklist/tasks/' + this.user.id + '/' + this.house_id + '?actionType=' + this.actionType + '&active=true' );
        if(r.status == 200) {
        	this.data = r.data;
        }
    	},
    },
  }
</script>

<style scoped>

	.block {
		min-height: 120px;
    background-color: var(--cozh-white);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    position: relative;
    padding: 15px;
	}

	.block:not(:last-child) {
		margin-bottom: 10px;
	}

	.block .badge {
		position: absolute;
		right: 15px;
		top: 15px;
	}
	.block__name {
		font-size: 18px;
    font-weight: 600;
    line-height: 1.3;
    padding-right: 90px;
	}

	.block__bottom {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-start;
		width: 100%;
    margin-top: 20px;
	}

	.block__btn {
		height: 32px;
    width: 32px;
    border: 1px solid rgba(0, 141, 210, 0.35);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin-right: 10px;
	}

	.block__btn img {
		transform:  rotate(180deg);
		margin-left: 2px;
	}

	.block__text {
		font-size: 13px;
		font-weight: 600;
		line-height: 1;
    color: var(--cozh-blue);
	}



  @media (max-width: 767px) {}

</style>