<template>

  <div class="load-container" :class="{'load': isLoad}">
    <template v-if="!isLoad && !isError">
      
      <div class="info-container mb-0" >
        <div class="info-container__item" v-if="company">
          <div class="info-container__label">
            Компания
          </div>  
          <div class="info-container__text">
            {{ company.name }}
          </div>
        </div>
        <div class="info-container__item">
          <div class="info-container__label">
            Статус
          </div>  
          <div class="info-container__text">
            {{ profile.active ? 'Активен' : 'Не активен' }}
          </div>
        </div>
        <div class="info-container__item">
          <div class="info-container__label">
            Логин
          </div>  
          <div class="info-container__text">
            {{ profile.login }}
          </div>
        </div>
        <div class="info-container__item">
          <div class="info-container__label">
            Фамилия
          </div>  
          <div class="info-container__text">
            {{ profile.lastName }}
          </div>
        </div>
        <div class="info-container__item">
          <div class="info-container__label">
            Имя
          </div>  
          <div class="info-container__text">
            {{ profile.firstName }}
          </div>
        </div>
        <div class="info-container__item" v-if="profile.middleName">
          <div class="info-container__label">
            Отчество
          </div>  
          <div class="info-container__text">
            {{ profile.middleName }}
          </div>
        </div>
        <div class="info-container__item" v-if="role">
          <div class="info-container__label">
            Роль
          </div>  
          <div class="info-container__text">
            {{ role.description }}
          </div>
        </div>
        <div class="info-container__item" v-if="profile.roleId">
          <div class="info-container__label">
            Квалификация
          </div>  
          <div class="info-container__text">
            {{ functions }}
          </div>
        </div>
      </div>

    </template>
  </div>

  <a class="btn btn--default btn--lg btn--inline mt-1" @click="logout($event)"> 
    <div class="btn--text"> Выйти </div>
    <div class="btn--loader"></div>
  </a>

</template>

<script setup>
  // import ImagePicker from '../components/ux/ImagePicker.vue';
</script>

<script>
  export default {
    name: 'PageProfile',
    data: () => ({
      isLoad: true,
      isError: false,
      profile: null,
    }),
    created () {
      Promise.all([ 
        this.$store.dispatch('getRoles'),
        this.$store.dispatch('getCompanies'),
      ])
      .then(() => {
        this.beforeRender();
        this.isLoad = false;
      })
      .catch((e)=> {
        console.log(e);
        this.isError = true;
      })
    },
    computed: {
      functions () {
        let res = '';
        this.profile.functions.forEach((item, index) => {
          if(index == this.profile.functions.length - 1) {
            res += item.description;
          } else {
            res += item.description + ', ';
          }
        });
        return res;
      },
      role () {
        return this.$store.getters.getRoleById(this.profile.roleId);
      },
      company () {
        return this.$store.getters.getCompanyById(this.profile.companyId);
      },
    },
    methods: {

      beforeRender () {
        // При создании клонируем объект профиля в локальную переменную
        this.profile = { ...this.$store.getters.authUser };
      },

      async logout (e) {
        const btn = e.currentTarget;
        btn.classList.add('btn--load');
        await new Promise(r => setTimeout(r, 500));
        this.$router.push({name: 'PageLogin'}).then(() => {
          this.$store.dispatch('logout');
        });
      }

    }
  }
</script>

<style scoped>

  @media (max-width: 767px) {}

</style>