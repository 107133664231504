<template>
  <div class="header">
    <img :src="'/img/' + ico" class="header__ico">
    <div class="header__name">
      {{ title }}
    </div>
	</div>
</template>

<script>
  export default {
    name: 'topMenu',
    data: () => ({
    }),
    computed: {
      title () {
        return this.$route.meta.title;
      },
      ico () {
        return this.$route.meta.ico;
      },
    },
    mounted () {
    },
    methods: {
    }
  }
</script>

<style scoped>

  .header {
    padding: 0px 20px;
    background-color: var(--cozh-white);
    border-radius: 8px;
    height: 46px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 10px;
  }

  .header__name {
    color: var(--cozh-black);
    font-weight: 600;
    font-size: 12px;
    line-height: 1;
    text-transform: uppercase;
  }

  .header__ico {
    margin-right: 10px;
  }

  @media (max-width: 767px) {}

</style>